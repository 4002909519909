<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Taux de conversion</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        <b-form-select
          v-model="selected"
          :options="filter"
          @change="onChange"
        />
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          xl="12"
          sm="12"
        >
          <b-media no-body>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ percent }}
              </h4>
            </b-media-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48">
                <feather-icon
                  size="24"
                  icon="PercentIcon"
                />
              </b-avatar>
            </b-media-aside>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body> </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BFormSelect,
} from 'bootstrap-vue';
import { filterByDuration } from '@/services/constant';
import customerServices from '@/services/customerServices';
import prospectServices from '@/services/prospectServices';
import moment from 'moment';
import commonServices from '@/services/commonServices';
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BFormSelect,
  },
  data() {
    return {
      filter: filterByDuration,
      selected: null,
      percent: 0,
      prospect: [],
      verified: [],
    };
  },
  created(){
    this.selected = this.filter[0].value;
    this.onChange(this.selected);
  },
  methods: {
    async getListVerified(variables) {
      this.isLoading = true;
      await customerServices
        .getList(variables)
        .then((result) => {
          if (result) {
            this.verified = result.data.listCustomers.items;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getListProspects(variables) {
      this.isLoading = true;

      await prospectServices
        .getList(variables)
        .then((result) => {
          if (result) {
            this.prospect = result.data.listProspects.items;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    onChange(value) {
      let result = [];
      const now = moment().add(-1, 'days');
      
      if (value === 'yesterday') {
        result = commonServices.getYesterday(now); 
      } else if (value === 'last_week') {
        result = commonServices.getLastWeek(now); 
      } else if (value === 'last_month'){
        result = commonServices.getLastMonth(now);
      } else if (value === 'last_3months'){
        result = commonServices.getLastThreeMonths(now);
      } else if (value === 'last_year'){
        result = commonServices.getLastLastYear(now);
      }
      const variablesCustomer = {
        filter: {
          createdAtTimestamp: {
            ge: result[0],
            le: result[1],
          },
          verified: {
            eq: true,
          },
        },
      };
      const variablesProspect = {
        filter: {
          createdAtTimestamp: {
            ge: result[0],
            le: result[1],
          },
          statusCode: {
            eq: 'ACTIVE',
          },
          verified: {
            eq: false,
          },
        },
      };
      this.getListVerified(variablesCustomer);
      this.getListProspects(variablesProspect);
      if (this.prospect.length > 0){
        this.percent = this.verified.length / this.prospect.length;
      } else {
        this.percent = 0;
      }
    },
  },
};
</script>
