<template>
  <div>
    <b-row class="match-height">
      <b-col cols="12">
        <EcommerceStatistics />
      </b-col>
      <b-col cols="6">
        <ChartjsDoughnutChart />
      </b-col>
     
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import ChartjsDoughnutChart from './pages/home/ChartjsDoughnutChart.vue';
import EcommerceStatistics from './pages/home/EcommerceStatistics.vue'
export default {
  components: {
    BRow,
    BCol,
    ChartjsDoughnutChart,
    EcommerceStatistics,
  },
};
</script>
