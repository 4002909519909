<template>
  <b-row>
    <b-col
      xl="6"
      sm="6"
    >
      <StatisticOne />
    </b-col>
    <b-col
      xl="6"
      sm="6"
    >
      <StatisticTwo />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'; 
import StatisticOne from './charts-components/StatisticOne.vue';
import StatisticTwo from './charts-components/StatisticTwo.vue';

export default {
  components: {
    StatisticOne,
    StatisticTwo,
    BRow,
    BCol,
  }, 
};
</script>
