<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Répartition lettre de mission</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        <b-form-select
          v-model="selected"
          :options="filter"
          @change="onChange"
        />
      </b-card-text>
    </b-card-header>

    <b-card-body class="statistics-body"> 
      <b-row>
        <b-col
          xl="12"
          sm="12"
        >
          <ChartjsComponentDoughnutChart
            v-if="!isLoading"
            :height="275"
            :data="chartjsData.doughnutChart.data"
            :options="chartjsData.doughnutChart.options"
            class="mb-3"
          />
          <div
            v-for="(stock, index) in labels"
            :key="stock.device"
            :class="index < labels.length - 1 ? 'mb-1' : ''"
            class="d-flex justify-content-between"
          >
            <div class="d-flex align-items-center"> 
              <span class="font-weight-bold ml-75 mr-25">{{ stock }}</span>
              <span>- {{ data[index] }}%</span>
            </div> 
          </div>
        </b-col>
      </b-row>
    <!--/ stocks -->
    </b-card-body>
  </b-card>
</template>

<script> 
import {
  BCard, BCardHeader, BCardTitle, BRow,
  BCol, BFormSelect, BCardBody, BCardText,
} from 'bootstrap-vue';
import customerServices from '@/services/customerServices';
import { filterByDuration } from '@/services/constant';
import commonServices from '@/services/commonServices';
import moment from 'moment';
import ChartjsComponentDoughnutChart from './charts-components/ChartjsComponentDoughnutChart.vue';
import chartjsData from './chartjsData'; 

export default {
  components: {
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormSelect,
    BCardHeader,
    BCardTitle,
    ChartjsComponentDoughnutChart,
    BCard,
  },
  data() {
    return {
      isLoading: true,
      chartjsData,
      filter: filterByDuration,
      selected: null,
      labels: ['Comptable', 'Juridique'],
      data: [0, 0],
      backgroundColor: ['red', 'green'], 
    };
  },
  created(){
    this.selected = this.filter[0].value;
    this.onChange(this.selected);
  },
  methods: {
    async getList(variables) { 
      this.isLoading = true; 
      await customerServices
        .getList(variables)
        .then((result) => {
          if (result) {
            const data = result.data.listCustomers.items;
            const accounting = data.filter(x => x.company_already_created === true);
            const legal = data.filter(x => x.company_already_created === false);
            if (data.length > 0){
              this.data = [this.calcPercent(accounting.length, data.length), this.calcPercent(legal.length, data.length)];
            } else {
              this.data = [0, 0]
            }
            this.chartjsData.doughnutChart.data.datasets[0].labels = this.labels;
            this.chartjsData.doughnutChart.data.datasets[0].data = this.data; 
            this.chartjsData.doughnutChart.data.datasets[0].backgroundColor = this.backgroundColor; 
          }
        })
        .catch(() => {})
        .finally(() => { 
          this.isLoading = false;
        });
    },
    calcPercent(value, total){
      return (value / total) * 100;
    },
    onChange(value) {
      let result = [];
      const now = moment().add(-1, 'days');
      if (value === 'yesterday') {
        result = commonServices.getYesterday(now); 
      } else if (value === 'last_week') {
        result = commonServices.getLastWeek(now); 
      } else if (value === 'last_month'){
        result = commonServices.getLastMonth(now);
      } else if (value === 'last_3months'){
        result = commonServices.getLastThreeMonths(now);
      } else if (value === 'last_year'){
        result = commonServices.getLastLastYear(now);
      }
      const variablesCustomer = {
        filter: {
          createdAtTimestamp: {
            ge: result[0],
            le: result[1],
          },
          verified: {
            eq: true,
          },
        },
      };  
      this.getList(variablesCustomer);  
    },
  },
  
};
</script>
